import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { post, get, patch } from '../../utils/fetch'; // Helper for HTTP requests

// 1. Categories APIs
export const addCategory = createAsyncThunk(
  'expense/addCategory',
  ({ payload }) => new Promise((resolve, reject) => resolve(post(`expense/categories`, payload))),
);

export const getCategories = createAsyncThunk(
  'expense/getCategories',
  () => new Promise((resolve, reject) => resolve(get(`expense/categories`))),
);

// 2. Expenses APIs
export const addExpense = createAsyncThunk(
  'expense/addExpense',
  ({ payload }) => new Promise((resolve, reject) => resolve(post(`expense/add`, payload))),
);

export const getAllExpenses = createAsyncThunk(
  'expense/getAllExpenses',
  () => new Promise((resolve, reject) => resolve(get(`expense/get-all`))),
);

export const updateExpense = createAsyncThunk(
  'expense/updateExpense',
  ({ id, payload }) => new Promise((resolve, reject) => resolve(patch(`expense/${id}`, payload))),
);

// 3. Opening Balance APIs
export const setOpeningBalance = createAsyncThunk(
  'expense/setOpeningBalance',
  ({ payload }) =>
    new Promise((resolve, reject) => resolve(post(`expense/opening-balance`, payload))),
);

export const getReports = createAsyncThunk(
  'expense/getReports',
  () => new Promise((resolve, reject) => resolve(get(`expense/reports/get-all`))),
);
