import { createSlice } from '@reduxjs/toolkit';
import * as Actions from './actions'; // Import all actions
import { addCaseWithLoading } from '../../utils/helpers';

const initialState = {
  expenses: [],
  categories: [],
  openingBalanceHistory: [],
  remainingAmount: null,
  totalExpenses: null,
  totalOpeningBalance: null,
  isLoading: false,
  error: null,
};

const expenseSlice = createSlice({
  name: 'expense',
  initialState,
  reducers: {}, // No direct reducers for now
  extraReducers: (builder) => {
    // Handle Categories
    addCaseWithLoading(builder, Actions.addCategory, {
      onCompleted: (state, action) => {
        let newCategory = action.payload.data.category;
        if (Array.isArray(state.categories)) {
          state.categories.push(newCategory);
        } else {
          state.categories = [newCategory];
        }
      },
      onReject: (state, action) => {
        state.error = action.payload;
      },
    });

    addCaseWithLoading(builder, Actions.getCategories, {
      onCompleted: (state, action) => {
        state.categories = action.payload.data.categories;
      },
    });

    // Handle Expenses
    addCaseWithLoading(builder, Actions.addExpense, {
      onCompleted: (state, action) => {
        let newExpense = action.payload.data.expense;
        if (Array.isArray(state.expenses)) {
          state.expenses.push(newExpense);
        } else {
          state.expenses = [newExpense];
        }
      },
      onReject: (state, action) => {
        state.error = action.payload;
      },
    });

    addCaseWithLoading(builder, Actions.getAllExpenses, {
      onCompleted: (state, action) => {
        state.expenses = action.payload.data.expenses;
      },
    });

    // Handle Opening Balance
    addCaseWithLoading(builder, Actions.setOpeningBalance, {
      onCompleted: (state, action) => {
        state.openingBalance = action.payload.data.balance.currentBalance;
      },
      onReject: (state, action) => {
        state.error = action.payload;
      },
    });

    addCaseWithLoading(builder, Actions.getReports, {
      onCompleted: (state, action) => {
        state.remainingAmount = action.payload.data.remainingAmount;
        state.totalExpenses = action.payload.data.totalExpenses;
        state.totalOpeningBalance = action.payload.data.totalOpeningBalance;
        state.openingBalanceHistory = action.payload.data.openingBalanceHistory;
      },
    });

    addCaseWithLoading(builder, Actions.updateExpense, {
      onCompleted: (state, action) => {
        const updatedExpense = action.payload.data.expense;
        state.expenses = state.expenses.map((expense) =>
          expense?._id === updatedExpense?._id ? updatedExpense : expense,
        );
      },
      onReject: (state, action) => {
        state.error = action.payload;
      },
    });
  },
});

export const {
  addCategory,
  addExpense,
  getAllExpenses,
  updateExpense,
  getCategories,

  setOpeningBalance,
  getReports,
} = Actions;
export default expenseSlice.reducer;
